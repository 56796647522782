import React from 'react';
import styled from 'styled-components';

import app from '../firebase'

import { Paper, AppBar, Toolbar, Button, IconButton, Menu, MenuItem } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import whiteTransparentLogo from '../assets/white_transparent_logo.png'
import { primary } from '../colors'

export interface PageHeaderProps {
    hideLogout?: boolean
}

const PageHeader = (props: PageHeaderProps) => {
    const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = React.useState(null)

    const handleSettingsMenuClick = (event: any) => {
        setSettingsMenuAnchorEl(event.currentTarget);
    }

    const handleSettingsMenuLoseFocus = () => {
        setSettingsMenuAnchorEl(null)
    }

    return (
        <PageHeaderWrapper>
            <Paper elevation={1}>
                <AppBar position="static">
                    <Toolbar>
                        <ToolbarContentsWrapper>
                            <LogoWrapper>
                                <a href="/">
                                    <img src={whiteTransparentLogo} alt="Logo" className="logo-img" />
                                </a>
                            </LogoWrapper>
                            <UserGuideButtonWrapper>
                                <a href="https://www.okayar.com/user-guide" target="_blank" rel="noopener noreferrer">
                                    <Button color="inherit">User Guide</Button>
                                </a>
                            </UserGuideButtonWrapper>
                            {props.hideLogout ? null : 
                                <SettingsMenuWrapper>
                                    <IconButton
                                        onClick={(event) => handleSettingsMenuClick(event)}
                                        size="small"
                                        aria-controls="settings-menu" 
                                        aria-haspopup="true"
                                    >
                                        <SettingsIcon style={{color:"white"}} />
                                    </IconButton>
                                    <Menu
                                    id="settings-menu"
                                    anchorEl={settingsMenuAnchorEl}
                                    keepMounted
                                    open={Boolean(settingsMenuAnchorEl)}
                                    onClose={handleSettingsMenuLoseFocus}
                                    >
                                        <MenuItemWrapper>
                                            <MenuItem onClick={() => app.auth().signOut()} className="settings-menu-item">Logout</MenuItem>
                                        </MenuItemWrapper>
                                    </Menu>
                                </SettingsMenuWrapper>
                            }
                        </ToolbarContentsWrapper>
                    </Toolbar>
                </AppBar>
            </Paper>
        </PageHeaderWrapper>
    )
}

const MenuItemWrapper = styled.div`
    .settings-menu-item {
        font-size: 0.75rem;
    }
`

const UserGuideButtonWrapper = styled.div`
    display: flex;
    align-items: center;

    .MuiButton-label {
        font-size: 0.7rem;
        text-transform: none;
    }

    a {
        text-decoration: none;
        color: white;
    }
`

const SettingsMenuWrapper = styled.div`
    display: flex;
    align-items: center;

    .MuiButton-label {
        font-size: 0.7rem;
        text-transform: none;
    }
`

const LogoWrapper = styled.div`
    position: absolute;
    transform: translate(-50%);
    left: 50%;

    width: 85px;
    padding-top: 5px;
    padding-bottom: 5px;

    .logo-img {
        width: 100%;
    }
`

const ToolbarContentsWrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;

    .MuiTypography-h6 {
        position: relative;
        transform: translate(-50%);
        left: 50%;
    }
`

const PageHeaderWrapper = styled.div`
    .MuiAppBar-colorPrimary {
        background-color: ${primary};
    }
    .MuiToolbar-regular {
        min-height: 52px;
    }
    z-index: 1;
`

export default PageHeader;
import React from 'react';
import styled from 'styled-components';

import { Paper, Typography, CircularProgress, IconButton } from '@material-ui/core'
import { createMuiTheme, responsiveFontSizes, MuiThemeProvider } from '@material-ui/core/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import { OkayarHomeComponent, PageBodyWrapper } from './common-styled-divs'
import PageHeader from './page-header'
import OkayarList from './okayar-list'
import NewOkayarEntryField from './new-okayar-entry-field'
import { IObjective } from '../interfaces'
import { AuthContext } from '../auth';
import { loadOkayars } from '../functions/api'
import { calclulatePercentQuarterlyCompletion } from '../functions/simple'
import { primary, offWhite, successDark } from '../colors'

export interface OkayarHomeProps {}

const OkayarHome = (props: OkayarHomeProps) => {
    let theme = createMuiTheme({
        palette: {
          primary: {
            main: primary
          }
        },
      });
    theme = responsiveFontSizes(theme);

    const [loadedObjectivesAndKeyResults, setLoadedObjectivesAndKeyResults] = React.useState<IObjective[]>()
    const [shouldRefreshObjectives, setShouldRefreshObjectives] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [apiError, setApiError] = React.useState<boolean>(false)

    const currMonth: number = new Date().getMonth() + 1
    const currQuarter: number = Math.ceil(currMonth/3)
    const currYear: number = new Date().getFullYear()

    const [selectedQuarter, setSelectedQuarter] = React.useState<number>(currQuarter)
    const [selectedYear, setSelectedYear] = React.useState<number>(currYear)

    const [percentCompleted, setPercentCompleted] = React.useState<number>(0)

    const {currentUser} = React.useContext(AuthContext)

    document.title = "Okayar - Q" + selectedQuarter + ", " + selectedYear

    React.useEffect(() => {
        async function fetchData() {
            try {
                let res = await loadOkayars(selectedQuarter, selectedYear, currentUser)
                setLoadedObjectivesAndKeyResults(res)
                setPercentCompleted(calclulatePercentQuarterlyCompletion(res))
            } catch (e) {
                setApiError(true)
            } finally {
                setShouldRefreshObjectives(false)
                setIsLoading(false)
            }
        }
        fetchData()
    }, [selectedQuarter, selectedYear, shouldRefreshObjectives, currentUser])

    const navigatePreviousQuarter = () => {
        setIsLoading(true)
        let newQuarter = selectedQuarter - 1
        if (newQuarter === 0) {
            setSelectedQuarter(4)
            setSelectedYear(selectedYear - 1)
        } else {
            setSelectedQuarter(newQuarter)
        }
    }

    const navigateNextQuarter = () => {
        setIsLoading(true)
        let newQuarter = selectedQuarter + 1
        if (newQuarter === 5) {
            setSelectedQuarter(1)
            setSelectedYear(selectedYear + 1)
        } else {
            setSelectedQuarter(newQuarter)
        }
    }

    return (
        <MuiThemeProvider theme={theme}>
            <OkayarHomeComponent>
                <Snackbar open={apiError} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} autoHideDuration={10000} onClose={() => setApiError(false)}>
                    <Alert severity="error" variant="filled" onClose={() => setApiError(false)}>
                        Something went wrong!
                    </Alert> 
                </Snackbar>
                <PageHeader />
                <PageBodyWrapper>
                    <CurrentOkrsContainer>
                        <Paper elevation={1} className="main-content-container">
                            <QuarterHeaderContainer>
                                <QuarterHeaderLeftSideContainer>
                                    <IconButton edge="start" color="inherit" size="small" 
                                        onClick={() => navigatePreviousQuarter()}
                                        disabled={(selectedQuarter===1 && selectedYear===2020) || isLoading}
                                    >
                                        <NavigateBeforeIcon />
                                    </IconButton>
                                    <Typography variant="h5">
                                        Quarter {selectedQuarter}, {selectedYear}
                                    </Typography>
                                    <IconButton edge="start" color="inherit" size="small"
                                        onClick={() => navigateNextQuarter()}
                                        disabled={
                                            (currQuarter === 4 ? (selectedQuarter===4 && selectedYear===currYear+1) : (selectedQuarter===4 && selectedYear===currYear)) || isLoading}
                                    >
                                        <NavigateNextIcon />
                                    </IconButton>
                                </QuarterHeaderLeftSideContainer>
                                <QuarterHeaderRightSideContainer completed={percentCompleted===100}>
                                    {!isLoading && loadedObjectivesAndKeyResults && loadedObjectivesAndKeyResults.length > 0 ? 
                                        <TypographyWrap>
                                            <Typography variant="body1" className="percent-completion">
                                                Quarterly Score:&nbsp;
                                            </Typography>
                                            <Typography variant="body1" className="percent-completion-num">
                                                {percentCompleted}%
                                            </Typography>
                                        </TypographyWrap>
                                    : null }
                                </QuarterHeaderRightSideContainer>
                            </QuarterHeaderContainer>
                            {isLoading ? 
                                <LoadingIconWrapper>
                                    <CircularProgress />
                                </LoadingIconWrapper>
                            :
                                <OkayarList
                                    loadedObjectivesAndKeyResults={loadedObjectivesAndKeyResults}
                                    setLoadedObjectivesAndKeyResults={setLoadedObjectivesAndKeyResults}
                                    setShouldRefreshObjectives={setShouldRefreshObjectives}
                                    setIsLoading={setIsLoading}
                                    setApiError={setApiError}
                                />
                            }
                            <NewOkayarEntryField 
                                setShouldRefreshObjectives={setShouldRefreshObjectives}
                                setIsLoading={setIsLoading}
                                setApiError={setApiError}
                                selectedQuarter={selectedQuarter}
                                selectedYear={selectedYear}
                                shouldShowInfo={!isLoading && typeof(loadedObjectivesAndKeyResults) !== "undefined" && loadedObjectivesAndKeyResults.length === 0}
                            />
                        </Paper>
                    </CurrentOkrsContainer>
                </PageBodyWrapper>
            </OkayarHomeComponent>
        </MuiThemeProvider>
    )
}

const LoadingIconWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
`

const TypographyWrap = styled.div`
    display: flex;
`

const QuarterHeaderRightSideContainer = styled.div<{ completed: boolean }>`
    display: flex;
    align-items: center;

    color: ${p => p.completed ? successDark : primary};

    .percent-completion-num {
        font-weight: bold;
    }
`

const QuarterHeaderLeftSideContainer = styled.div`
    display: flex;
    align-items: center;
`

const QuarterHeaderContainer = styled.div`
    flex-wrap: wrap;
    
    .MuiTypography-h5 {
        font-size: 1.4rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
    padding-top: 1.5rem;
    margin-left: 5%;
    margin-right: 5%;

    justify-content: space-between;
    display: flex;
`

const CurrentOkrsContainer = styled.div<{ loadedObjectivesAndKeyResults?: IObjective[] }>`
    display: flex;
    width: 80%;
    min-width: 300px;

    .main-content-container {
        padding-bottom: 2rem;
    }

    .MuiPaper-root {
        background-color: ${offWhite};
        margin-top: 1.5rem;
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .MuiPaper-rounded {
        border-radius: 20px;
    }
`

export default OkayarHome;
import { IObjective, IKeyResult } from '../interfaces'

export const calclulatePercentQuarterlyCompletion = (objectives: IObjective[]) => {
    if (!objectives || objectives.length === 0) {
        return 0
    }

    let completedValue = 0
    objectives.forEach((objective) => {
        completedValue += calclulatePercentObjectiveCompletion(objective.KeyResults)/100
    })

    let averageCompletedValue = completedValue/objectives.length

    return Math.round(100 * averageCompletedValue)
}

export const calclulatePercentObjectiveCompletion = (keyResults: IKeyResult[]) => {
    if (!keyResults || keyResults.length === 0) {
        return 0
    }

    let completedValue = 0
    keyResults.forEach((keyResult) => {
        completedValue += keyResult.NumericProgress/keyResult.NumericGoal
    })

    let averageCompletedValue = completedValue/keyResults.length

    return Math.round(100 * averageCompletedValue)
} 
import React from 'react';
import styled from 'styled-components';

import { IObjective } from '../interfaces'
import ObjectiveCard from './objective-card'

export interface OkayarListProps {
    loadedObjectivesAndKeyResults?: IObjective[]
    setLoadedObjectivesAndKeyResults: (newObjectives: IObjective[]) => void
    setShouldRefreshObjectives: (val: boolean) => void
    setIsLoading: (val: boolean) => void
    setApiError: (val: boolean) => void
}

const OkayarList = (props: OkayarListProps) => {    
    return (
        <OkayarListWrapper>
            {props.loadedObjectivesAndKeyResults && props.loadedObjectivesAndKeyResults.map((objective, _) =>
                <ObjectiveCard 
                objective={objective} 
                setShouldRefreshObjectives={props.setShouldRefreshObjectives}
                setIsLoading={props.setIsLoading}
                setApiError={props.setApiError}
                key={objective.Description} 
                />
            )}
        </OkayarListWrapper>
    )
}

const OkayarListWrapper = styled.div`
    width: 90%;
    margin-left: 5%;

    padding-top: 0.5rem;
`

export default OkayarList;
import React from 'react';
import styled from 'styled-components';

import { IconButton, Button, TextField, Tooltip } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';

import { AuthContext } from '../auth';
import { createKeyResult } from '../functions/api'
import { primary, secondary, offWhite, darkGray } from '../colors'

export interface NewKeyResultEntryFieldProps {
    objectiveId: number
    numKeyResults: number

    setShouldRefreshObjectives: (val: boolean) => void
    setIsLoading: (val: boolean) => void
    setApiError: (val: boolean) => void
}

const NewKeyResultEntryField = (props: NewKeyResultEntryFieldProps) => {
    const [isTypingNewKeyResult, setIsTypingNewKeyResult] = React.useState<boolean>(false)
    const [keyResultDescription, setKeyResultDescription] = React.useState<string>("")
    const [keyResultNumericGoal, setKeyResultNumericGoal] = React.useState<number>(0)

    const [descriptionError, setDescriptionError] = React.useState<boolean>(false)
    const [goalError, setGoalError] = React.useState<boolean>(false)

    const {currentUser} = React.useContext(AuthContext)

    const getGoalAsString = () => {
        if (keyResultNumericGoal === 0) {
            return ""
        } else {
            return String(keyResultNumericGoal)
        }
    }

    const cleanUpNewKrEntryBox = () => {
        setIsTypingNewKeyResult(false)

        setKeyResultDescription("")
        setKeyResultNumericGoal(0)

        setDescriptionError(false)
        setGoalError(false)
    }

    const handleKeyResultCreateSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        setDescriptionError(keyResultDescription === "")
        setGoalError(keyResultNumericGoal === 0)

        if (keyResultDescription === "" || keyResultNumericGoal === 0) {
            return
        }

        props.setIsLoading(true)
        try {
            await createKeyResult(props.objectiveId, keyResultDescription, keyResultNumericGoal, currentUser)
            props.setShouldRefreshObjectives(true)
            cleanUpNewKrEntryBox()
        } catch (e) {
            props.setIsLoading(false)
            props.setApiError(true)
        }
    }

    const ButtonToStartTypingNewKeyResult =
        <NewKeyResultButtonWrapper numKeyResults={props.numKeyResults}>
            <Button
            variant="contained"
            disableElevation
            onClick={() => setIsTypingNewKeyResult(true)}>
                + Add a key result
            </Button>
        </NewKeyResultButtonWrapper> 

    const EnterNewObjectiveComponent = 
        <NewKeyResultFormOuterWrapper>
            <form className="create-key-result-form" onSubmit={handleKeyResultCreateSubmit}>
                <NewKeyResultFormInnerWrapper>
                    <NewKRLeftSideWrapper>
                        <NewKeyResultFormDescriptionWrapper>
                            <TextField
                                id="new-kr-description-field"
                                label="Description"
                                variant="outlined"
                                error={descriptionError}
                                helperText={descriptionError ? "Description must not be empty." : null}
                                value={keyResultDescription}
                                onChange={(e) => setKeyResultDescription(e.target.value)}
                            />                            
                        </NewKeyResultFormDescriptionWrapper>
                        <Tooltip 
                            title="Every key result should include a measurable goal. 
                            If this is a &quot;yes or no&quot; goal, enter 1." 
                            arrow
                        >
                            <NewKeyResultFormGoalWrapper>
                                <TextField
                                    id="new-kr-goal-field"
                                    label="Goal #"
                                    variant="outlined"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    error={goalError}
                                    helperText={goalError ? "Cannot be 0." : null}
                                    value={getGoalAsString()}
                                    onChange={(e) => setKeyResultNumericGoal(Number(e.target.value))}
                                />
                            </NewKeyResultFormGoalWrapper>
                        </Tooltip>
                    </NewKRLeftSideWrapper>
                    <NewKRRightSideWrapper>
                        <IconButton className="kr-icon-buttons" edge="start" color="inherit" size="small" type="button" onClick={cleanUpNewKrEntryBox}>
                            <CancelOutlinedIcon />
                        </IconButton>
                        <IconButton className="kr-icon-buttons" edge="start" color="inherit" type="submit">
                            <CheckIcon />
                        </IconButton>
                    </NewKRRightSideWrapper>
                </NewKeyResultFormInnerWrapper>
            </form>
        </NewKeyResultFormOuterWrapper>

    return (
        <AddNewKeyResultWrapper>
            {isTypingNewKeyResult ? EnterNewObjectiveComponent : ButtonToStartTypingNewKeyResult}
            {props.numKeyResults === 0 && !isTypingNewKeyResult ? 
                <Tooltip title="Use this to create a key result for this objective!" placement="right">
                    <InfoIcon />
                </Tooltip>
            : null}
        </AddNewKeyResultWrapper>
    )
}

const NewKRRightSideWrapper = styled.div`
    flex-direction: row;
    display: flex;

    .MuiIconButton-root {
        padding: 0;
        padding-left: 12px;
        padding-right: 12px;
    }

    .MuiSvgIcon-root {
        width: 1.2rem;
        height: 1.2rem;
    }

    .kr-icon-buttons:hover {
        background-color: transparent;
    }
`

const NewKRLeftSideWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
`

const NewKeyResultFormGoalWrapper = styled.div`
    margin-left: 1rem;
    width: 18%;
`

const NewKeyResultFormDescriptionWrapper = styled.div`    
    margin-left: 2rem;
    width: 60%;
`

const NewKeyResultFormInnerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .MuiFormControl-root {
        width: 100%;
    }
`

const NewKeyResultFormOuterWrapper = styled.div`
    box-shadow: 0px 0px 0px 1px ${secondary};
    margin-top: 1px;
    width: 85%;

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${primary};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${primary};
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${primary};
    }

    .MuiOutlinedInput-input {
        font-size: 14px;
        padding: 12px 12px 10px;
    }
    .MuiInputLabel-outlined {
        font-size: 14px;
        transform: translate(14px, 12px) scale(1);
    }
`

const NewKeyResultButtonWrapper = styled.div<{ numKeyResults: number }>`
    .MuiButton-label {
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        text-transform: none;
    }

    .MuiButton-disableElevation {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 0px 0px 1px ${secondary};

        background-color: ${p => p.numKeyResults > 0 ? `${offWhite}` : `${darkGray}` };

        padding: 4px 6px;
        padding-left: 2rem;
        padding-right: 1rem;

        margin-top: 1px;
    }

    &:hover .MuiButton-disableElevation {
        box-shadow: 0px 0px 0px 1px ${secondary};
    }
`

const AddNewKeyResultWrapper = styled.div`
    display: flex;

    .MuiSvgIcon-root {
        width: 0.7em;
        margin-left: 0.3rem;
        margin-top: 0.1rem;
    }
`

export default NewKeyResultEntryField;
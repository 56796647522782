import styled from 'styled-components';

import { secondary } from '../colors'

export const PageBodyWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${secondary};

    flex-grow: 1;
    flex-direction: column;
`

export const OkayarHomeComponent = styled.div`
    display: flex;
    flex-direction: column;
`
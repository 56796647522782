import React from 'react';
import styled from 'styled-components';

import LinearProgress from '@material-ui/core/LinearProgress';
import { IconButton, Typography, Paper, Input, FormControl, FormHelperText } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckIcon from '@material-ui/icons/Check';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { IKeyResult } from '../interfaces'
import { AuthContext } from '../auth'
import { editKeyResult, deleteKeyResult } from '../functions/api'
import { primary, secondary, lightGray, successDark } from '../colors'

export interface KeyResultCardProps {
    keyResult:   IKeyResult
    objectiveId: number

    setShouldRefreshObjectives: (val: boolean) => void
    setIsLoading: (val: boolean) => void
    setApiError: (val: boolean) => void
}

const KeyResultCard = (props: KeyResultCardProps) => {
    const [isEditingKeyResult, setIsEditingKeyResult] = React.useState<boolean>(false)
    const [keyResultDescription, setKeyResultDescription] = React.useState<string>(props.keyResult.Description)
    const [keyResultNumericProgress, setKeyResultNumericProgress] = React.useState<number>(props.keyResult.NumericProgress)
    const [keyResultNumericGoal, setKeyResultNumericGoal] = React.useState<number>(props.keyResult.NumericGoal)

    const [descriptionError, setDescriptionError] = React.useState<boolean>(false)
    const [goalError, setGoalError] = React.useState<boolean>(false)
    const [progressError, setProgressError] = React.useState<boolean>(false)

    const {currentUser} = React.useContext(AuthContext)

    const percentCompleted: number = Math.round(100 * 100 * props.keyResult.NumericProgress/props.keyResult.NumericGoal)/100

    const handleKeyResultDelete = () => {
        confirmAlert({
            title: 'Are you sure?',
            message: `Your key result "${props.keyResult.Description}" will not be recoverable.`,
            buttons: [
              {
                label: 'Yes',
                onClick: async () => {
                    props.setIsLoading(true)
                    try {
                        await deleteKeyResult(props.objectiveId, props.keyResult.ID, currentUser)
                        props.setShouldRefreshObjectives(true)
                    } catch (e) {
                        props.setIsLoading(false)
                        props.setApiError(true)
                    }
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
        });
    }

    const resetKeyResultEdit = () => {
        setIsEditingKeyResult(false)

        setKeyResultDescription(props.keyResult.Description)
        setKeyResultNumericProgress(props.keyResult.NumericProgress)
        setKeyResultNumericGoal(props.keyResult.NumericGoal)

        setDescriptionError(false)
        setGoalError(false)
        setProgressError(false)
    }

    const handleKeyResultEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        setDescriptionError(keyResultDescription === "")
        setGoalError(keyResultNumericGoal === 0)
        setProgressError(keyResultNumericProgress > keyResultNumericGoal)

        if (keyResultDescription === "" || keyResultNumericGoal === 0 || keyResultNumericProgress > keyResultNumericGoal) {
            return
        }

        props.setIsLoading(true)
        try {
            await editKeyResult(props.objectiveId, props.keyResult.ID, keyResultDescription, keyResultNumericProgress, keyResultNumericGoal, currentUser)
            props.setShouldRefreshObjectives(true)
            resetKeyResultEdit()
        } catch (e) {
            props.setIsLoading(false)
            props.setApiError(true)
        }
    }

    return (
        <KeyResultCardWrapper>
            <Paper className="key-result-title" elevation={0}>
                {!isEditingKeyResult ?
                    <UneditedContents>
                        <KeyResultCardLeftSideWrapper>
                            <Typography variant="body2" className="key-result-description">
                                {props.keyResult.Description}
                            </Typography>
                        </KeyResultCardLeftSideWrapper>
                        <KeyResultCardRightSideWrapper>
                            <NumericProgressWrapper isEditing={false}>
                                <Typography variant="body2" className="non-editable-typography">{props.keyResult.NumericProgress}</Typography>
                                <Typography variant="body2" className="non-editable-typography"> / </Typography>
                                <Typography variant="body2" className="non-editable-typography">{props.keyResult.NumericGoal}</Typography>
                            </NumericProgressWrapper>
                            <ProgressBarWrapper completed={props.keyResult.NumericProgress === props.keyResult.NumericGoal}>
                                <span className="progress-descriptor">{percentCompleted}%</span>
                                <LinearProgress variant="determinate" value={percentCompleted} className="kr-linear-progress" />
                            </ProgressBarWrapper>
                            <KeyResultModifyButtonWrapper>
                                <IconButton className="kr-icon-buttons" edge="start" color="inherit" size="small" onClick={() => setIsEditingKeyResult(true)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton className="kr-icon-buttons" edge="start" color="inherit" onClick={() => handleKeyResultDelete()}>
                                    <DeleteIcon />
                                </IconButton>
                            </KeyResultModifyButtonWrapper>
                        </KeyResultCardRightSideWrapper>
                    </UneditedContents> 
                :
                    <ErrorMessageWrapper>
                        <EditableContents>
                            <form className="edit-key-result-form" onSubmit={handleKeyResultEditSubmit}>
                                <KeyResultEditWrapper>
                                    <FormControl>
                                        <div>
                                            <Input
                                                value={keyResultDescription}
                                                disableUnderline
                                                onChange={(e) => setKeyResultDescription(e.target.value)}
                                            />
                                            <FormHelperText>Description</FormHelperText>
                                        </div>
                                    </FormControl>
                                </KeyResultEditWrapper>
                                <KeyResultCardRightSideWrapper>
                                    <NumericProgressWrapper isEditing={true}>
                                        <FormControl>
                                            <div>
                                                <Input
                                                    className="numeric-kr-inputs"
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    value={String(keyResultNumericProgress)}
                                                    disableUnderline
                                                    onChange={(e) => setKeyResultNumericProgress(Number(e.target.value))}
                                                />
                                                <FormHelperText>Progress</FormHelperText>
                                            </div>
                                        </FormControl>
                                        <Typography variant="body2" className="custom-slash"> / </Typography>
                                        <FormControl>
                                            <div>
                                                <Input
                                                    className="numeric-kr-inputs"
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    value={String(keyResultNumericGoal)}
                                                    disableUnderline
                                                    onChange={(e) => setKeyResultNumericGoal(Number(e.target.value))}
                                                />
                                                <FormHelperText>Goal</FormHelperText>
                                            </div>
                                        </FormControl>
                                    </NumericProgressWrapper>
                                    <ProgressBarWrapper completed={props.keyResult.NumericProgress === props.keyResult.NumericGoal}>
                                        <span className="progress-descriptor">{percentCompleted}%</span>
                                        <LinearProgress variant="determinate" value={percentCompleted} className="kr-linear-progress" />
                                    </ProgressBarWrapper>
                                    <KeyResultModifyButtonWrapper>
                                        <IconButton className="kr-icon-buttons" edge="start" color="inherit" size="small" type="button" onClick={resetKeyResultEdit}>
                                            <CancelOutlinedIcon />
                                        </IconButton>
                                        <IconButton className="kr-icon-buttons" edge="start" color="inherit" type="submit">
                                            <CheckIcon />
                                        </IconButton>
                                    </KeyResultModifyButtonWrapper>
                                </KeyResultCardRightSideWrapper>
                            </form>
                        </EditableContents>
                        <FormHelperWrap>
                            {descriptionError ? <FormHelperText error>Description must not be empty.</FormHelperText> : null}
                            {progressError ? <FormHelperText error>Progress cannot be greater than Goal.</FormHelperText> : null}
                            {goalError ? <FormHelperText error>Goal cannot be 0.</FormHelperText> : null}
                        </FormHelperWrap>
                    </ErrorMessageWrapper>
                }
            </Paper>
        </KeyResultCardWrapper>
    )
}

const FormHelperWrap = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-right: 2rem;
`

const ErrorMessageWrapper = styled.div`
    width: 100%;
    flex-direction: column;
`

const KeyResultEditWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    margin-right: 1.5rem;

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiInput-root {
        width: 100%;
    }
`

const EditableContents = styled.div`
    display: flex;
    width: 100%;
    padding-top: 0.3rem;

    .edit-key-result-form {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .MuiOutlinedInput-input {
        padding: 8px 8px;
        font-size: 14px;
    }

    .MuiFormHelperText-root {
        font-size: 10px;
    }

    .numeric-kr-inputs {
        width: 2.5rem;
    }

    .custom-slash {
        margin-top: 4px;
        font-size: 16px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .MuiInputBase-input {
        font-size: 14px;
        padding: 0;
    }

    .MuiFormControl-root {
        justify-content: space-around;
    }
`

const UneditedContents = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`

const NumericProgressWrapper = styled.div<{ isEditing: boolean }>`
    width: ${props => props.isEditing ? '6rem' : '3rem'};
    margin-right: ${props => props.isEditing ? '2rem' : '1rem'};
    flex-direction: row;
    display: flex;

    .non-editable-typography {
        margin: 2px;
        padding: 0;
    }
`

const ProgressBarWrapper = styled.div< { completed: boolean } >`
    display: flex;
    flex-direction: column;
    width: ${p => window.innerWidth < 800 ? "4rem" : "8rem" };

    .progress-descriptor {
        font-size: 10px;
        color: ${p => p.completed ? successDark : primary};
        font-weight: bold;
    }

    .kr-linear-progress {
        margin-top: 2px;
        margin-right: 1rem;
        margin-bottom: 4px;
        border-radius: 2px;

        .MuiLinearProgress-barColorPrimary {
            background-color: ${p => p.completed ? successDark : primary};
        }
    }

    .MuiLinearProgress-colorPrimary {
        background-color: ${secondary};
    }
`

const KeyResultCardLeftSideWrapper = styled.div`
    display: flex;
    width: 2rem; /* just a finite value makes this work */
    flex-grow: 1;
`

const KeyResultCardRightSideWrapper = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

const KeyResultModifyButtonWrapper = styled.div`
    flex-direction: row;
    display: flex;

    .MuiIconButton-root {
        padding: 0;
        padding-left: 12px;
        padding-right: 12px;
    }
`

const KeyResultCardWrapper = styled.div`
    .key-result-title {
        border-radius: 4px !important;

        margin: 0 !important;
        padding-left: 2rem !important;

        border-top: 1px ${lightGray} solid !important;

        min-height: 2rem !important;
        display: flex !important;
        align-items: center !important;

        width: inherit !important;
    }

    .kr-icon-buttons:hover {
        background-color: transparent;
    }

    .key-result-description {
        margin-right: 1.5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .MuiSvgIcon-root {
        width: 1rem;
        height: 1rem;
    }
`

export default KeyResultCard;
import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

export interface PrivacyAndTermsProps {}

const PrivacyAndTerms = (props: PrivacyAndTermsProps) => {
    return (
        <LinkWrapper>
            <Typography variant="body2" className="privacy-and-terms">
                <b>Notice:</b> By signing up and/or logging in, you are agreeing to the&nbsp;
                <Link to={{ pathname: "https://www.iubenda.com/privacy-policy/82579696/legal" }} target="_blank">Privacy Policy</Link>
                &nbsp;and the&nbsp;
                <Link to={{ pathname: "https://okayar-public-assets.s3-us-west-2.amazonaws.com/Okayar+Terms+%26+Conditions.pdf" }} target="_blank">Terms & Conditions</Link>
                . Your creation or usage of an account also implies consent to the use of cookies to track user sessions.
            </Typography>
        </LinkWrapper>
    )
}

const LinkWrapper = styled.div`
    margin-top: 1.5rem;

    .privacy-and-terms {
        font-size: 0.75rem;
    }
`

export default PrivacyAndTerms;
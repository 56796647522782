import React from 'react';
import styled from 'styled-components';

import { IconButton, Input, Card, Button, Tooltip } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';

import { AuthContext } from '../auth';
import { addNewObjective } from '../functions/api'
import { primary, secondary, offWhite, errorMain } from '../colors'

export interface NewOkayarEntryFieldProps {
    selectedQuarter: number
    selectedYear: number
    shouldShowInfo: boolean

    setShouldRefreshObjectives: (val: boolean) => void
    setIsLoading: (val: boolean) => void
    setApiError: (val: boolean) => void
}

const NewOkayarEntryField = (props: NewOkayarEntryFieldProps) => {
    const [isTypingNewObjective, setIsTypingNewObjective] = React.useState(false)
    const [newObjectiveValue, setNewObjectiveValue] = React.useState<string>("")

    const [titleError, setTitleError] = React.useState<boolean>(false)

    const {currentUser} = React.useContext(AuthContext)

    const cleanUpNewObjectiveEntryBox = () => {
        setIsTypingNewObjective(false)
        
        setNewObjectiveValue("")
        setTitleError(false)
    }

    const handleObjectiveSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (newObjectiveValue === "") {
            setTitleError(true)
            return
        }

        setIsTypingNewObjective(false)
        props.setIsLoading(true)
        try {
            await addNewObjective(newObjectiveValue, props.selectedQuarter, props.selectedYear, currentUser)
            props.setShouldRefreshObjectives(true)
            cleanUpNewObjectiveEntryBox()
        } catch (e) {
            props.setIsLoading(false)
            props.setApiError(true)
            setIsTypingNewObjective(true)
        }
    }

    const ButtonToStartTypingNewObjective =
        <NewObjectiveButtonWrapper>
            <Button
            variant="contained" 
            disableElevation
            onClick={() => setIsTypingNewObjective(true)}>
                + Add another objective
            </Button>
        </NewObjectiveButtonWrapper>
    
    const EnterNewObjectiveComponent =
        <EnterNewObjectiveWrapper>
            <Card className="objective-input-card">
                <form onSubmit={handleObjectiveSubmit}>
                    <HorizontalInputWrap>
                        <InputWrapDiv titleError={titleError}>
                            <Input 
                            placeholder="Enter objective title"
                            value={newObjectiveValue}
                            onChange={(e) => setNewObjectiveValue(e.target.value)}
                            disableUnderline />
                        </InputWrapDiv>
                    </HorizontalInputWrap>
                    {titleError ?
                        <FormHelperWrap>
                            <FormHelperText error>Title cannot be empty.</FormHelperText>
                        </FormHelperWrap>
                    : null}
                    <CreateAndCloseWrap>
                        <IconButton 
                        color="inherit"
                        edge="start"
                        type="button"
                        disableFocusRipple
                        disableRipple
                        onClick={() => cleanUpNewObjectiveEntryBox()}>
                            <CloseIcon />
                        </IconButton>
                        <Button 
                        variant="contained" 
                        type="submit">
                            Create
                        </Button>
                    </CreateAndCloseWrap>
                </form>
            </Card>
        </EnterNewObjectiveWrapper>
    
    return (
        <EntryFieldWrapper>
            <RelativePositioningWrapper>
                {isTypingNewObjective ? EnterNewObjectiveComponent : ButtonToStartTypingNewObjective}
                {props.shouldShowInfo ? 
                <InformationIconWrapper>
                    <Tooltip title="Use this to create your first objective!" placement="right">
                        <InfoIcon />
                    </Tooltip>
                </InformationIconWrapper>
                : null}
            </RelativePositioningWrapper>
        </EntryFieldWrapper>
    )
}

const CreateAndCloseWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;

    .MuiButton-contained {
        background-color: ${primary};
        color: white;
        right: 5%;

        height: 40px;
        padding: 6px 14px;
    }

    &:hover .MuiButton-contained {
        background-color: ${primary} !important;
    }

    .MuiIconButton-colorInherit {
        left: 5%;
        height: 40px;
        width: 40px;
    }

    .MuiButton-label {
        font-size: 12px;
    }
`

const FormHelperWrap = styled.div`
    margin-left: 5%;
`

const HorizontalInputWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
`

const InputWrapDiv = styled.div<{ titleError: boolean }>`
    background: ${offWhite};
    width: 90%;
    box-shadow: ${p => p.titleError ? `0px 0px 0px 2px ${errorMain}` : `0px 0px 0px 1px ${primary}`};
    border-radius: 2px;

    .MuiInputBase-root {
        border-radius: 2px;
        background: ${offWhite};

        padding: 5px;
        width: 100%;
    }

    .MuiInputBase-input {
        font-size: 14px;
    }
`

const EnterNewObjectiveWrapper = styled.div`
    width: 100%;

    .objective-input-card {
        background: ${secondary} !important;
        border-radius: 5px !important;

        border: 1px solid ${primary} !important;

        display: flex !important;
        flex-direction: column !important;

        margin: 0 !important;
    }
`

const NewObjectiveButtonWrapper = styled.div`
    width: 100%;

    .MuiButton-label {
        font-size: 0.7rem;
    }

    .MuiButton-disableElevation {
        width: 100%;
        border-radius: 2px;
        box-shadow: 0px 0px 0px 1px ${primary};
    }

    &:hover .MuiButton-disableElevation {
        box-shadow: 0px 0px 0px 1px ${primary};
    }
`

const InformationIconWrapper = styled.div`
    position: absolute;
    right: -1.5rem;

    .MuiSvgIcon-root {
        width: 0.7em;
    }
`

const RelativePositioningWrapper = styled.div`
    position: relative;

    width: 50%;
    min-width: 250px;
    max-width: 425px;

    display: flex;
    justify-content: center;
`

const EntryFieldWrapper = styled.div`
    padding-top: 1rem;

    display: flex;
    justify-content: center;
`

export default NewOkayarEntryField;
export const primary = '#6E4695'
export const secondary = '#D2C4E0'

export const offWhite = '#ebecf0'
export const lightGray = '#D3D3D3'
export const darkGray = '#e0e0e0'

export const errorLight = '#e57373'
export const errorMain = '#f44336'
export const errorDark = '#d32f2f'

export const successLight = '#81c784'
export const successMain = '#4caf50'
export const successDark = '#388e3c'
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from './private-route'

import { AuthProvider } from './auth'
import OkayarHome from './components/okayar-home';
import Login from './components/login'

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Switch>
                    <PrivateRoute exact path="/" component={OkayarHome} />
                    <Route path="/login" component={Login}/>
                </Switch>
            </Router>
        </AuthProvider>
    )
}

export default App;
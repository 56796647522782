import React from 'react';

import { OkayarHomeComponent, PageBodyWrapper } from './common-styled-divs'
import PageHeader from './page-header'

export interface EmptyOkayarHomeProps {}

const EmptyOkayarHome = (props: EmptyOkayarHomeProps) => {   
    return (
        <OkayarHomeComponent>
            <PageHeader hideLogout />
            <PageBodyWrapper>
            </PageBodyWrapper>
        </OkayarHomeComponent>
    )
}

export default EmptyOkayarHome;
import React from 'react';
import styled from 'styled-components';

import { Input, Typography, Card, Paper, IconButton, Menu, MenuItem, Button } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import KeyResultCard from './key-result-card'
import { IObjective } from '../interfaces'
import NewKeyResultEntryField from './new-key-result-entry-field';
import { AuthContext } from '../auth';
import { editObjective, deleteObjective } from '../functions/api'
import { calclulatePercentObjectiveCompletion } from '../functions/simple'
import { primary, secondary, successDark } from '../colors'

export interface ObjectiveCardProps {
    objective: IObjective
    setShouldRefreshObjectives: (val: boolean) => void
    setIsLoading: (val: boolean) => void
    setApiError: (val: boolean) => void
}

const ObjectiveCard = (props: ObjectiveCardProps) => {
    const [newObjectiveValue, setNewObjectiveValue] = React.useState<string>(props.objective.Description)
    const [titleError, setTitleError] = React.useState<boolean>(false)

    const [objectiveMenuAnchorEl, setObjectiveMenuAnchorEl] = React.useState(null)
    const [isEditingObjectiveTitle, setIsEditingObjectiveTitle] = React.useState<boolean>(false)

    const percentCompleted = calclulatePercentObjectiveCompletion(props.objective.KeyResults)

    const {currentUser} = React.useContext(AuthContext)

    const handleObjectiveDelete = () => {
        confirmAlert({
            title: 'Are you sure?',
            message: `Your objective "${props.objective.Description}" and all associated key results will not be recoverable.`,
            buttons: [
              {
                label: 'Yes',
                onClick: async () => {
                    props.setIsLoading(true)
                    try {
                        await deleteObjective(props.objective.ID, currentUser)
                        props.setShouldRefreshObjectives(true)
                    } catch (e) {
                        props.setIsLoading(false)
                        props.setApiError(true)
                    }
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
        });
    }

    const handleObjectiveMenuClick = (event: any) => {
        setObjectiveMenuAnchorEl(event.currentTarget);
    }

    const handleObjectiveMenuLoseFocus = () => {
        setObjectiveMenuAnchorEl(null)
    }

    const handleEditSelect = () => {
        setIsEditingObjectiveTitle(true)
        setObjectiveMenuAnchorEl(null)
    }

    const handleDeleteSelect = () => {
        handleObjectiveDelete()
        setObjectiveMenuAnchorEl(null)
    }

    const resetObjectiveEdit = () => {
        setIsEditingObjectiveTitle(false)
        
        setNewObjectiveValue(props.objective.Description)
        setTitleError(false)
    }

    const handleObjectiveEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (newObjectiveValue === "") {
            setTitleError(true)
            return
        }

        props.setIsLoading(true)
        try {
            await editObjective(props.objective.ID, newObjectiveValue, currentUser)
            props.setShouldRefreshObjectives(true)
            resetObjectiveEdit()
        } catch (e) {
            props.setIsLoading(false)
            props.setApiError(true)
        }
    }

    return (
        <SingleObjectiveCardWrapper>
            <Card className="single-objective-card">
                <ObjectiveHeaderWrapper>
                    <ObjectiveTitleWrapper>
                        {!isEditingObjectiveTitle ? 
                            <Paper className="objective-title" elevation={0}>
                                <Typography variant="body1" className="objective-description">
                                    {props.objective.Description}
                                </Typography>
                            </Paper> 
                        : 
                            <ObjectiveEditWrapper>
                                <form onSubmit={handleObjectiveEditSubmit} className="edit-objective-title-form">
                                    <ObjectiveEditInputRow>
                                        <Input 
                                            multiline
                                            error={titleError}
                                            placeholder="Enter new objective title"
                                            value={newObjectiveValue}
                                            onChange={(e) => setNewObjectiveValue(e.target.value)}    
                                        />
                                    </ObjectiveEditInputRow>
                                    {titleError ?
                                        <FormHelperWrap>
                                            <FormHelperText error>Title cannot be empty.</FormHelperText>
                                        </FormHelperWrap>
                                    : null}
                                    <ObjectiveEditButtonRow>
                                        <Button className="objective-edit-buttons objective-submit-button" type="submit">Submit New Title</Button>
                                        <Button 
                                        className="objective-edit-buttons" 
                                        type="button"
                                        onClick={() => resetObjectiveEdit()}
                                        >Cancel</Button>
                                    </ObjectiveEditButtonRow>
                                </form>
                            </ObjectiveEditWrapper>
                        }
                    </ObjectiveTitleWrapper>
                    <ObjectiveHeaderRightSideWrapper>
                        {!isEditingObjectiveTitle ?
                            <PercentCompletionWrapper completed={percentCompleted===100}>
                                <Typography variant="body2" className="percent-completion">
                                    Score:&nbsp;
                                </Typography>
                                <Typography variant="body2" className="percent-completion-num">
                                    {percentCompleted}%
                                </Typography>
                            </PercentCompletionWrapper>
                        : null}
                        <IconButton
                            onClick={(event) => handleObjectiveMenuClick(event)}
                            size="small"
                            aria-controls="objective-controls-menu" 
                            aria-haspopup="true"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                        id="objective-controls-menu"
                        anchorEl={objectiveMenuAnchorEl}
                        keepMounted
                        open={Boolean(objectiveMenuAnchorEl)}
                        onClose={handleObjectiveMenuLoseFocus}
                        >
                            <MenuItemWrapper>
                                <MenuItem onClick={handleEditSelect} className="objective-menu-item">Edit</MenuItem>
                                <MenuItem onClick={handleDeleteSelect} className="objective-menu-item">Delete</MenuItem>
                            </MenuItemWrapper>
                        </Menu>
                    </ObjectiveHeaderRightSideWrapper>
                </ObjectiveHeaderWrapper>
                {props.objective.KeyResults.map((keyResult) =>
                    <KeyResultCard 
                    keyResult={keyResult} 
                    objectiveId={props.objective.ID}
                    setShouldRefreshObjectives={props.setShouldRefreshObjectives}
                    key={keyResult.Description}
                    setIsLoading={props.setIsLoading}
                    setApiError={props.setApiError}
                    />
                )}
            </Card>
            <NewKeyResultEntryField 
            setShouldRefreshObjectives={props.setShouldRefreshObjectives}
            objectiveId={props.objective.ID}
            numKeyResults={props.objective.KeyResults.length}
            setIsLoading={props.setIsLoading}
            setApiError={props.setApiError}
            />
        </SingleObjectiveCardWrapper>
    )
}

const FormHelperWrap = styled.div`
    margin-left: 1rem;
`

const ObjectiveEditButtonRow = styled.div`
    flex-direction: row;
    padding-top: 0.5rem;
`

const ObjectiveEditInputRow = styled.div`
    .MuiInput-root {
        width: 100%;
        margin-left: 1rem;
    }
`

const ObjectiveEditWrapper = styled.div`
    flex-direction: column;
    display: flex;

    .objective-edit-buttons {
        width: auto;
        color: ${primary};
        margin-left: 1rem;
        font-size: 10px;
        margin-bottom: 5px;

        text-transform: none;
    }

    .edit-objective-title-form {
        width: 97%;
        flex-direction: column;
    }
`

const MenuItemWrapper = styled.div`
    .objective-menu-item {
        font-size: 0.75rem;
    }
`
const PercentCompletionWrapper = styled.div<{ completed: boolean }>`
    display: flex;
    align-items: center;
    padding-left: 3px;

    color: ${p => p.completed ? successDark : primary};

    .objective-progress-descriptor {
        font-size: 10px;
        width: 3.5rem;
        text-align: right;
        padding-right: 4px;
    }

    .percent-completion {
        font-size: 0.75rem;
    }

    .percent-completion-num {
        font-size: 0.75rem;
        font-weight: bold;
    }
`

const ObjectiveHeaderRightSideWrapper = styled.div`
    display: flex;
`

const ObjectiveHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .MuiSvgIcon-root {
        height: 0.8em;
    }
`

const ObjectiveTitleWrapper = styled.div`
    flex-grow: 1;
    
    .objective-title {
        border-radius: 4px !important;

        margin: 0 !important;
        margin-left: 1rem !important;
        width: inherit !important;

        min-height: 2.55rem !important;

        display: flex !important;
        align-items: center !important;
    }
    .objective-description {
        font-weight: bold;
        padding-top: 5px;
        padding-bottom: 5px;
    }
`

const SingleObjectiveCardWrapper = styled.div`
    .single-objective-card {
        border-radius: 4px !important;
        border-bottom-left-radius: 0 !important;
        box-shadow: 0px 0px 0px 1px ${secondary} !important;

        margin-bottom: 0 !important;
    }
`

export default ObjectiveCard;